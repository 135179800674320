.float-btn-wrapper {
  position: fixed;
  width: 90%;
  height: 48px;
  bottom: 66px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 998;
  animation: slideFromBottom 0.4s ease-in;
}

@keyframes slideFromBottom {
  from {
    bottom: 0px;
  }
  to {
    bottom: 60px;
  }
}
