@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.page-not-found-wrapper {
  @include wrapper-shimmer;
  min-height: calc(100vh - 200px) !important;
  display: grid;
  place-content: center;
  .page-not-found-container {
    @include container-shimmer;
    background-color: transparent !important;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .page-not-found-illustration {
      margin-bottom: 10px;
      width: 100%;
    }
    .go-home-button {
      width: 116px;
      height: 40px;
      margin: 10px auto;
    }
  }
}
