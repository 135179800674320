@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.item-details-wrapper {
  @include wrapper-shimmer;
  background-color: $white-color;
  width: 95%;
  .item-details-container {
    @include container-shimmer;
    padding: 0;
    margin-top: 0;
    .item-details-section {
      .item-content {
        .image-wrapper {
          position: relative;
          .item-img {
            width: 100%;
            max-height: 320px;
            object-fit: cover;
            height: 100%;
          }
          .favorite-indicator-wrapper {
            width: 30px;
            height: 34px;
            border-radius: 5px;
          }
        }
        .item-info {
          margin-top: 16px;
          .title-wrapper {
            padding: 0 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .product-name-wrapper {
              width: 100%;
              display: flex;
              .name-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .product-name {
                  @include truncate(2);
                  margin-inline-end: 20px;
                }
                .button-wrapper {
                  white-space: nowrap;
                  display: flex;
                  align-items: flex-start;
                  .out-of-stock-cta {
                    width: 92px;
                    height: 28px;
                  }

                  .button-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .add-to-cart-cta {
                      padding: 0 2.5px;
                    }
                  }
                }
              }
            }
            .item-rating-wrapper {
              .rating-count,
              .reviews-count {
                font-size: 14px;
                margin-inline-start: 4px;
              }
            }
          }
          .price-details-wrapper {
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .pricing-details-wrapper {
              padding: 0 16px;
              display: flex;
              width: 100%;
              justify-content: space-between;
              .original-item-price {
                display: inline;
                text-decoration: line-through;
                color: $santas-gray;
                padding-right: 5px;
              }
              .discounted-item-price {
                display: inline;
                padding-right: 6px;
                line-height: normal;
              }
              .item-quantity {
                padding-left: 6px;
                font-size: 12px;
                margin-left: auto;
                color: $scrollbar-gray-color;
              }
            }
            .button-wrapper {
              position: fixed;
              bottom: 10%;
              display: flex;
              align-items: center;
              gap: 8px;
              width: 100%;
              z-index: 1000;
              .out-of-stock-cta {
                width: 92px;
                height: 28px;
              }
              .button-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                .add-to-cart-cta {
                  padding: 0 2.5px;
                  height: 48px;
                  width: 90%;
                  margin: 2px;
                  white-space: nowrap;
                  box-shadow: 0px 2px 8px rgba(119, 119, 119, 0.16);
                }
                .count-exists-wrapper {
                  display: flex;
                  width: 95%;
                  justify-content: space-around;
                  .counter-btn {
                    height: 48px;
                    width: 30%;
                    box-shadow: 0px 2px 8px rgba(119, 119, 119, 0.16);
                    .counter-wrapper {
                      width: 80%;
                      .incrementor,
                      .decrementor {
                        height: 11px;
                        width: 11px;
                      }
                    }
                  }
                  .view-cart-btn {
                    height: 48px;
                    width: 60%;
                  }
                }
              }
            }
          }
          .item-extra-details-wrapper {
            margin-top: 16px;
            .item-details-tab-list {
              width: max-content;
              display: flex;
              width: 100%;
              box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.16);
              .item-details-tab {
                min-width: 60px;
                padding: 6px 16px;
                text-align: center;
                cursor: pointer;
              }
            }
            .tab-details-wrapper {
              margin: 24px 0;
              padding: 0 16px;
              .description-tab-content {
                font-size: 12px;
                line-height: 24px;
              }
              .other-tab-content {
                .other-tab-content-para {
                  margin-bottom: 20px;
                }
              }
            }
          }
        }
      }
      .item-content-placeholder {
        .image-wrapper {
          .item-img-placeholder {
            width: 100%;
            height: 100%;
            min-height: 200px;
            //  max-height: 320px;
          }
        }
        .item-info-placeholder {
          margin-top: 32px;
          .description-placeholder {
            height: 16px;
            margin-bottom: 36px;
            border-radius: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .item-details-wrapper {
    margin-bottom: 20px;
    .item-details-container {
      .item-details-section {
        min-height: calc(100vh - 350px);
        .item-content {
          display: flex;
          .image-wrapper {
            width: 55%;
            margin-inline-end: 16px;
            .item-img {
              max-height: 520px !important;
              margin-top: 0px !important;
              margin-top: 20px;
              border-radius: 16px;
            }
            .favorite-indicator-wrapper {
              width: 34px;
              height: 34px;
              .favorite-icon {
                width: 16px;
              }
            }
          }
          .item-info {
            width: 45%;
            margin-top: 0px;
            .title-wrapper {
              padding: unset;
              .product-name-wrapper {
                display: block;
                .diet-indicator {
                  width: 18px;
                }
              }
              .item-rating-wrapper {
                .rating-count,
                .reviews-count {
                  font-size: 16px;
                }
              }
            }
            .price-details-wrapper {
              margin-top: 16px;
              padding: unset;
              .button-wrapper {
                width: unset;
                position: unset;
                .out-of-stock-cta {
                  height: 32px;
                  width: 92px;
                }
                .button-container {
                  .add-to-cart-cta {
                    height: 36px;
                    min-width: 72px;
                    width: fit-content;
                  }
                  .count-exists-wrapper {
                    .view-cart-btn {
                      display: none;
                    }
                    .counter-btn {
                      height: 36px;
                      width: 84px;
                    }
                  }
                }
              }
            }
            .item-extra-details-wrapper {
              margin-top: 24px;
              .item-details-tab-list {
                box-shadow: unset;
                .item-details-tab {
                  min-width: 70px;
                }
              }
              .tab-details-wrapper {
                margin-top: 24px;
                .description-tab-content {
                  font-size: 14px;
                  max-height: 240px;
                  overflow-y: auto;
                }
                .other-tab-content {
                  .other-tab-content-para {
                    margin-bottom: 20px;
                    max-height: 240px;
                    overflow-y: auto;
                  }
                }
              }
            }
          }
        }
        .item-content-placeholder {
          display: flex;
          .image-wrapper {
            width: 55%;
            .item-img-placeholder {
              width: 100%;
              height: 100%;
              min-height: 220px;
              // max-height: 320px;
            }
          }
          .item-info-placeholder {
            width: 45%;
            margin-inline-start: 24px;
            margin-top: 12px;
            .description-placeholder {
              height: 16px;
              width: 60%;
              margin-bottom: 36px;
              border-radius: 20px;
            }
          }
        }
        .recommendations-widget-wrapper {
          padding-left: 0px;
          .section-heading,
          .item-list {
            padding-left: 0px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .item-details-wrapper {
    .item-details-container {
      .item-details-section {
        .item-content {
          .image-wrapper {
            .favorite-indicator-wrapper {
              .favorite-icon {
                width: 20px;
              }
            }
          }
          .item-info {
            .title-wrapper {
              .item-rating-wrapper {
                .rating-count,
                .reviews-count {
                  font-size: 20px;
                }
              }
            }
            .price-details-wrapper {
              .pricing-details-wrapper {
                display: block;
                .item-quantity {
                  padding-left: 6px;
                  margin-inline-start: unset;
                }
              }
            }
            .item-extra-details-wrapper {
              margin-top: 36px;
              .tab-details-wrapper {
                margin-top: 24px;
                .other-tab-content {
                  .other-tab-content-para {
                    margin-bottom: 20px;
                  }
                }
              }
            }
          }
        }
        .item-content-placeholder {
          .image-wrapper {
            width: 55%;
            .item-img-placeholder {
              width: 100%;
              height: 100%;
              min-height: 360px;
              max-height: 420px;
            }
          }
          .item-info-placeholder {
            width: 45%;
            .description-placeholder {
              height: 16px;
              width: 60%;
              margin-bottom: 36px;
              border-radius: 20px;
            }
          }
        }
      }
    }
  }
}
