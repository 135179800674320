@import "../../styles/colors.scss";

.recommendations-widget-wrapper {
  background-color: $white-color;
  border-radius: 8px;
  padding: 24px;
  .section-heading {
    margin-bottom: 8px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .recommendations-widget-wrapper {
    border-radius: 4px;
    .section-heading {
      margin-bottom: 24px;
    }
  }
}
