@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.carousel-item-wrapper,
.carousel-item-placeholder-wrapper {
  width: 100%;
  height: 240px;
  max-width: 180px;
  min-width: 180px;
  margin-inline-end: 20px;
  position: relative;
  border-radius: 8px;
  background-color: $alabaster-color;
  .carousel-item-image,
  .carousel-image-placeholder {
    width: 100%;
    height: 120px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    position: relative;
    display: flex;
    flex: 0 0 auto;
    object-fit: cover;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .carousel-item-content,
  .carousel-item-content-placeholder {
    width: 100%;
    box-sizing: border-box;
    padding: 12px 8px;
    border-radius: 8px;
    transition: all 0.5s;
    .card-title-wrapper,
    .carousel-item-title-plaholder {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      .product-name-wrapper {
        display: flex;
        align-items: flex-start;
        .product-name {
          line-height: 14px;
          @include truncate(2);
        }
      }
    }
  }
  .card-footer {
    width: 100%;
    bottom: 8%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: space-between;
    padding: 12px 8px 16px 8px;
    box-sizing: border-box;
    .pricing-details-wrapper {
      position: relative;
      line-height: 8px;
      margin-inline-end: 5px;
      .pricing-details {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: -10px;
        .original-item-price {
          display: inline;
          text-decoration: line-through;
          color: $santas-gray;
          padding-right: 5px;
        }
        .discounted-item-price {
          display: inline;
          padding-right: 5px;
        }
      }
      .item-quantity {
        font-size: 10px;
        margin-top: 0px;
        line-height: 130%;
        position: absolute;
        width: 110%;
        min-width: 100px;
        > b {
          font-weight: 400;
        }
      }
    }
    .action-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      .button-wrapper {
        .app-button,
        .counter-wrapper {
          width: 60px;
          padding: 0 2.5px;
          box-shadow: 0px 2px 8px rgba(119, 119, 119, 0.16);
        }
        .out-of-stock-cta {
          width: 92px;
        }
      }
      .customize-cta {
        font-size: 10px !important;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .carousel-item-wrapper,
  .carousel-item-placeholder-wrapper {
    max-width: 190px;
    min-width: 190px;
    height: 260px;
    border-bottom: 0px;
    border-radius: 8px;
    cursor: pointer;
    .carousel-item-image,
    .carousel-image-placeholder {
      width: 100%;
      height: 126px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      .favorite-indicator-wrapper {
        top: 10px;
        right: 10px;
      }
    }
    .carousel-item-content,
    .carousel-item-content-placeholder {
      .card-title-wrapper,
      .carousel-item-title-plaholder {
        .product-name-wrapper {
          .product-name {
            line-height: 18px;
          }
        }
      }
    }
    .card-footer {
      .pricing-details-wrapper {
        .pricing-details {
          .original-item-price {
            font-size: 11px;
          }
        }
      }
      .button-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .out-of-stock-cta {
          width: 92px;
        }
      }
    }
  }
}
