.cart-button {
  display: flex;
  width: 85%;
  justify-content: space-between;
  align-items: center;
  .details-wrapper {
    display: flex;
    align-items: center;
    .price-detail {
      border-left: 1px solid white;
      margin: 0 10px;
      padding: 0 5px;
    }
  }
}
