@import "../../styles/colors.scss";

.base-layout-container {
  width: 100%;
  min-height: 100%;
  position: relative;
  .view-wrapper {
    overflow-y: auto;
    flex: auto;
    transition: 0.5s;
  }
}

@media only screen and (min-width: 768px) {
  .base-layout-container {
    .view-wrapper {
      min-height: calc(100vh - 300px);
    }
  }
}
