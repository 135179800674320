@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.address-sidebar {
  padding: 0;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .address-sidebar {
    width: calc(100vw - 60%);
    max-width: 600px;
  }
}

.address-dialog {
  padding: 0;
  height: fit-content;
}

@media only screen and (min-width: 768px) {
  .address-dialog {
    height: 100%;
  }
}
