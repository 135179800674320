@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.accordian-wrapper {
  user-select: none;
  .accordian-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .accordian-chevron {
      padding: 8px;
      border-radius: 4px;
    }
    .chevron-down {
      transform: rotate(0deg);
    }
    .chevron-up {
      transform: rotate(180deg);
    }
  }
  .accordian-details {
    margin-top: 24px;
  }
}
