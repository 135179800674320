@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.change-password-dialog {
  width: 100%;
  padding: 16px;
  margin: 12px !important;
  box-sizing: border-box;
  max-width: 640px !important;
  border-radius: 16px !important;
  .change-password-form {
    .dialog-header {
      .heading-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .form-heading {
          line-height: 32px;
        }
      }
      .form-sub-heading {
        line-height: 32px;
        margin: 8px 0px 28px 0px;
      }
      .close-dialog-icon {
        cursor: pointer;
      }
    }
    .dialog-content {
      margin-top: 24px;
      border-radius: 12px;
      margin-bottom: 48px;
    }
    .dialog-footer {
      .continue-button {
        height: 60px;
        border-radius: 12px;
        text-transform: capitalize;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .change-password-dialog {
    padding: 24px;
  }
}

@media only screen and (min-width: 1200px) {
  .change-password-dialog {
    padding: 32px;
    .change-password-form {
      .dialog-header {
        .heading-wrapper {
          .form-heading {
            font-size: 24px;
          }
        }
        .form-sub-heading {
          margin: 16px 0px 48px 0px;
        }
      }
    }
  }
}
