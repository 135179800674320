@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.landing-screen-wrapper {
  @include wrapper-shimmer;
  background-color: $white-color;
  min-height: calc(100vh - 200px) !important;
  .content-wrapper {
    width: 100%;
    height: 100%;
    background-color: $white-color;
  }
}
