@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.catalogue-wrapper {
  margin-top: 0px;
  background: rgba(163, 163, 163, 0.12);
  .scrollTopMarker {
    height: 2px;
    width: 100px;
    position: absolute;
    top: 4%; // puts the tracker on banner
  }
}
.item-list-wrapper {
  position: relative;
  .list-wrapper {
    background-color: $white-color;
  }
  .category-list {
    display: none;
  }

  .applied-filters-wrapper {
    padding: 12px 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex-wrap: wrap;
    gap: 10px;
    .applied-filter {
      display: flex;
      padding: 4px 10px;
      border-radius: 8px;
      align-items: center;
      justify-content: space-between;
      .remove-filter-icon {
        cursor: pointer;
        padding-left: 12px;
      }
    }
  }

  .mobile-view-category-list-wrapper {
    .action-button-wrapper {
      width: fit-content;
    }
    .menu-background {
      opacity: 1;
      transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      z-index: 234;
      position: fixed;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-tap-highlight-color: transparent;
    }
  }

  .item-list,
  .item-list-placeholder {
    margin-top: 10px;
    .element {
      height: 100%;
      .section-heading-wrapper {
        display: flex;
        padding: 8px 0px;
        align-items: center;
        .section-heading {
          text-transform: capitalize;
        }
        .section-heading-desc {
          color: $santas-gray;
          margin-inline-start: 5px;
        }
      }
      .section-sub-heading-wrapper {
        padding: 8px 0px;
        .sub-heading-container {
          display: flex;
          align-items: center;
          .breadcrumb-chevron {
            width: 10px;
            height: 10px;
            margin: 0px 10px;
            transform: rotate(-90deg);
          }
        }
        .sub-heading-description {
          color: $santas-gray;
          line-height: 18px;
          margin-top: 6px;
        }
      }
      .accordian-details {
        margin-top: 0 !important;
      }
      .accordian-summary {
        min-height: 56px;
      }
      .category-item-list {
        padding: 0px !important;
        margin: 0px 0px;
      }
      .empty-search-result-wrapper {
        height: 300px;
        @include align-center;
        flex-direction: column;
        .no-results-found-icon {
          width: auto;
          height: 200px;
        }
        .helper-text {
          width: 400px;
          margin-top: 12px;
          text-align: center;
          line-height: 1.5em;
        }
      }
    }
  }

  .sticky {
    left: 0;
    top: 126px;
    width: 100%;
    z-index: 998;
    position: fixed;
    transition: all 1000ms ease;
    box-shadow: 0 8px 6px 0px rgba(163, 163, 164, 0.1);
    .category-list {
      margin-bottom: 0px;
    }
    .applied-filters-wrapper {
      padding: 0 12px 12px;
    }
  }
}

#category-link-list-popover {
  .mobile-view-link-list {
    padding: 8px;
    width: 280px;
    z-index: 222222;
    bottom: 125px;
    height: 300px;
    top: auto !important;
    border-radius: 12px;
    box-sizing: border-box;
    box-shadow: 0 4px 6px 4px rgba(163, 163, 164, 0.1) !important;
    .mobile-view-category-list {
      padding: 0px;
      margin: 0px;
      list-style: none;
      &:focus {
        outline: none;
      }
      .category {
        text-transform: capitalize;
        padding: 8px 22px;
        display: flex;
        flex-grow: 1;
        cursor: pointer;
        .category-link {
          color: $gray-shade;
          display: flex;
          flex-grow: 1;
          justify-content: space-between;
          align-items: center;
          .active-category-dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            position: absolute;
            left: 0;
          }
        }
      }
      .sub-category-list {
        list-style-type: none;
        padding: 0px 0px 0px 20px;
        .category {
          padding: 0 28px;
        }
      }
    }
  }
  .lift-up {
    bottom: 180px;
  }
}

@media only screen and (min-width: 768px) {
  .catalogue-wrapper {
    margin-top: 32px;
    .scrollTopMarker {
      top: 5%;
    }
  }
  .item-list-wrapper {
    padding: 0px;
    box-sizing: border-box;
    .list-wrapper {
      display: initial;
      width: 100%;
      padding: 0px 16px;
      box-sizing: border-box;
      background-color: $white-color;
      .container {
        @mixin category-scroll-button {
          top: 50%;
          transform: translateY(-50%);
          z-index: 10;
          padding: 20px 8px;
          cursor: pointer;
          position: absolute;
          @include align-center;
          background-color: $white-color;
        }
        .category-scroll-left-button {
          left: 0;
          @include category-scroll-button;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          .scroll-button-left-icon {
            transform: rotate(90deg);
          }
        }
        .category-scroll-right-button {
          right: 0;
          @include category-scroll-button;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          .scroll-button-right-icon {
            transform: rotate(-90deg);
          }
        }
        .category-list {
          width: 100%;
          display: flex;
          list-style: none;
          margin: 5px auto 5px auto;
          padding: 0px;
          white-space: nowrap;
          overflow: auto;
          box-sizing: border-box;
          scroll-behavior: smooth;
          &::-webkit-scrollbar {
            display: none;
          }
          .category {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            background-color: $alabaster-color;
            &:first-child {
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }
            &:last-child {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }
            &:hover {
              .sub-category-list {
                display: block !important;
              }
            }
            .category-link {
              flex-grow: 1;
              padding: 13px 16px;
              cursor: pointer;
              text-transform: capitalize;
              height: auto;
              color: $gray-shade;
              @include align-center;
              background-color: $alabaster-color;

              &:first-child {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
              }
              &:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
              }
              &.active {
                border-radius: 8px;
              }
              .category-name {
                white-space: nowrap;
              }
              .category-item-count {
                margin-inline-start: 5px;
                white-space: nowrap;
              }
              .chevron-down {
                width: 10px;
                height: auto;
                margin-inline-start: 8px;
              }
            }
            .sub-category-list {
              max-height: 50vh;
              overflow-y: auto;
              display: none;
              position: absolute;
              top: 55px;
              min-width: 140px;
              z-index: 5000;
              border-radius: 8px;
              padding: 12px 16px;
              background-color: $white-color;
              box-shadow: 0 4px 6px 4px rgba(163, 163, 163, 0.16);
              .sub-category-link {
                color: $gray-shade;
                .sub-category-text {
                  padding: 12px 0px;
                  cursor: pointer;
                  white-space: nowrap;
                }
              }
            }
          }
          .category-placeholder {
            min-width: 160px;
            height: 44px;
            padding: 16px 0px;
            box-sizing: border-box;
            display: inline-block;
            &:first-child {
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }
            &:last-child {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }
        }
      }
    }
    .applied-filters-wrapper {
      padding: 12px 0px 16px 0px;
      gap: 16px;
      .applied-filter {
        padding: 10px 16px;
      }
    }
    .mobile-view-category-list-wrapper {
      display: none;
    }
    .item-list-placeholder {
      margin-top: 60px;
    }
    .item-list,
    .item-list-placeholder {
      padding: 0px 12px;
      .element,
      .element-placeholder {
        height: 100%;
        min-height: 400px;
        .section-heading-wrapper {
          padding: 16px 0px 8px;
          .section-heading-desc {
            font-size: 16px;
          }
        }
        .category-description {
          margin-bottom: 24px;
        }
        .section-sub-heading-wrapper {
          margin: 12px 0px;
          padding: 16px 0px;
          .sub-heading-container {
            .sub-heading {
              font-size: 18px;
            }
            .breadcrumb-chevron {
              width: 12px;
              height: 12px;
            }
          }
          .sub-heading-description {
            margin-top: 5px;
          }
        }
        .category-item-list,
        .category-item-list-placeholder {
          margin: 12px 0px;
          display: grid;
          grid-gap: 30px;
          flex-wrap: wrap;
          justify-content: space-around;
          grid-template-columns: calc(50% - 30px) calc(50% - 30px);
        }
        .empty-search-result-wrapper {
          .empty-state-heading {
            font-size: 18px;
          }
        }
      }
    }
    .sticky {
      top: 120px;
      .sub-category-list {
        top: 55px;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .catalogue-wrapper {
    background: unset;
    .scrollTopMarker {
      top: 8%;
    }
  }
  .item-list-wrapper {
    .item-list,
    .item-list-placeholder {
      padding: 0px 12px;
      .element,
      .element-placeholder {
        .category-item-list,
        .category-item-list-placeholder {
          grid-template-columns: calc(33.33% - 30px) calc(33.33% - 30px) calc(
              33.33% - 30px
            );
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .catalogue-wrapper {
    .scrollTopMarker {
      top: 10%;
    }
  }
  .item-list-wrapper {
    .list-wrapper {
      padding: 0px;
    }
    .item-list,
    .item-list-placeholder {
      padding: 0px;
      .element,
      .element-placeholder {
        .section-heading {
          font-size: 20px;
        }
        .section-sub-heading-wrapper {
          .sub-heading-container {
            .sub-heading {
              font-size: 20px;
            }
            .sub-heading-description {
              font-size: 20px;
            }
          }
        }
        .category-item-list,
        .category-item-list-placeholder {
          grid-template-columns:
            calc(25% - 24px) calc(25% - 24px) calc(25% - 24px)
            calc(25% - 24px);
          grid-gap: 24px;
        }
      }
    }
  }
}
