@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.location-search-title-wrapper {
  display: flex;
  align-items: center;
  padding: 14px;
  justify-content: space-between;
  .location-search-title {
    font-size: 16px;
    line-height: 32px;
  }
  .close-dialog-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}

.search-results-wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  .dialog-header {
    display: flex;
    gap: 14px;
    margin: 10px;
  }
  .multiple-store-dialog-paper {
    .dialog-header {
      .arrow-back {
        margin-top: 2px;
      }
    }
  }

  .search-dialog-header {
    .location-search-box {
      width: 100%;
      padding: 16px;
      box-sizing: border-box;
      .MuiInputAdornment-positionStart {
        margin: 0px;
      }
      .MuiInputBase-root {
        border-radius: 10px;
        background-color: $alabaster-color;
        input {
          padding: 15px;
          font-size: 12px;
        }
        .location-icon {
          width: 18px;
          height: auto;
          cursor: pointer;
        }
      }
    }
  }
  .search-dialog-body {
    flex: 1 1;
    overflow-y: auto;
    min-height: 60vh;
    max-height: 60vh;
    .current-location-wrapper {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 20px 16px;
      border-bottom: 1px solid rgba(163, 163, 164, 0.16);
      > * {
        pointer-events: none; // to make sure children dont inherit background color
      }
      .current-location-icon {
        width: 20px;
        height: auto;
        display: flex;
        justify-content: center;
        margin-inline-end: 15px;
      }
      .current-location-text {
        max-width: 280px;
        letter-spacing: 1px;
        line-height: 1.45;
        margin: 5px 0px;
        text-shadow: inherit;
        text-transform: uppercase;
      }
      &:hover {
        .current-location-text {
          text-shadow: 0.5px 0 0;
        }
      }
    }
    .saved-address-list,
    .search-results-list {
      padding: 0px 0px 16px 0px;
      .section-heading {
        padding: 16px 16px 0px;
        text-transform: capitalize;
      }
      .address-item-wrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 16px 16px 18px 16px;
        border-bottom: 1px solid rgba(163, 163, 164, 0.16);
        > * {
          pointer-events: none; // to make sure children dont inherit background color
        }
        .address-icon {
          width: 20px;
          height: auto;
          display: flex;
          justify-content: center;
          margin-inline-end: 15px;
        }
        .address-text {
          .address-title {
            text-transform: uppercase;
            line-height: 1.45;
            letter-spacing: 1px;
          }
          .address {
            max-width: 280px;
          }
        }
        &:hover {
          .address-title {
            text-shadow: 0.5px 0 0;
          }
        }
      }
      .empty-search-state {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 50%;
        padding: 32px;
        @include align-center;
        .icon-wrapper {
          width: 64px;
          height: 64px;
          border-radius: 16px;
          background-color: $medium-gray-color;
          @include align-center;
        }
      }
    }
    .no-saved-addresses-wrapper {
      flex: 1 1;
      @include align-center;
      flex-direction: column;
      padding: 32px;
      .choose-location-illustration {
        width: auto;
        height: 120px;
      }
      .choose-location-text {
        padding: 16px 0px;
      }
    }
  }
  .search-dialog-footer {
    .store-locator-link-wrapper {
      width: 100%;
      height: 50px;
      padding: 8px 16px;
      @include align-center;
      box-sizing: border-box;
      cursor: pointer;
      box-shadow: 0 -4px 6px -2px rgba(163, 163, 163, 0.16);
      .store-locator-link {
        margin-inline-end: 16px;
      }
    }
  }
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 6px 4px rgba(163, 163, 163, 0.16);
}

@media only screen and (min-width: 768px) {
  .search-dialog-body {
    min-height: unset !important;
    max-height: unset !important;
  }
  .multiple-store-dialog-paper {
    width: 600px;
    height: 300px;
    border-radius: 8px !important;
  }
  .delivery-nowlater-dialog-paper {
    width: 600px;
    min-height: 400px;
    border-radius: 8px !important;
    height: fit-content;
  }
  .delivery-mode-button {
    min-height: 68px;
  }
  .continue-cta {
    bottom: 24px;
  }
  .dialog-header {
    display: flex;
    padding: 24px 24px 8px 24px;
    align-items: baseline;
    .arrow-back {
      cursor: pointer;
      width: 24px;
      height: 24px;
      margin-inline-end: 12px;
      margin-top: 4px;
      background: #f7f7f7;
      border-radius: 8px;
      @include align-center;
    }
    .close-dialog-icon {
      cursor: pointer;
    }
  }
}
