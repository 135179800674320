.image-carousel-wrapper {
  position: relative;
  padding: 12px 12px;
  .image-carousel-container {
    position: relative;
    height: 100%;
    margin: auto;
    .carousel-slide {
      display: none;
      height: 100%;
      position: relative;
      .carousel-image {
        width: 100%;
        height: 100%;
        vertical-align: middle;
        object-fit: cover;
        border-radius: 16px;
      }
      .carousel-markup {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
      }
    }
    .carousel-image-placeholder {
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }
  }
  .carousel-indicator {
    width: 30%;
    border-radius: 5px;
    height: 4px;
    margin: 12px auto;
    max-width: 200px;
    .slide {
      border-radius: 5px;
      max-width: 50%;
      height: 4px;
      transition-property: all;
      transition-duration: 300ms;
      transition-timing-function: linear;
    }
  }
}
/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px 14px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 8px;
  user-select: none;
  background: none;
  border: none;
  background-color: rgba(0, 0, 0, 0.8);
}

.prev {
  left: 1%;
}

/* Position the "next button" to the right */
.next {
  right: 1%;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}
@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev,
  .next,
  .text {
    font-size: 11px;
  }
}

@media only screen and (min-width: 768px) {
  .image-carousel-wrapper {
    padding: 8px 0;
    .carousel-indicator {
      margin: 24px auto;
      height: 8px;
      .slide {
        height: 8px;
      }
    }
  }
  .prev,
  .next {
    padding: 14px 18px;
  }
}
