@import "../../styles/mixins.scss";

.confirmation-dialog-root {
  .confirmation-dialog-container {
    .confirmation-dialog-paper {
      width: 100%;
      min-width: 300px;
      max-width: 400px;
      padding: 32px 16px;
      border-radius: 8px;
      box-sizing: border-box;
      .confirmation-form {
        .dialog-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .close-dialog-icon {
            cursor: pointer;
          }
        }
        .dialog-content {
          .confirmation-text {
            padding: 32px 0px;
          }
        }
        .dialog-footer {
          margin-top: 32px;
          .confirm-button {
            height: 50px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .confirmation-dialog-root {
    .confirmation-dialog-container {
      .confirmation-dialog-paper {
        padding: 32px;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .confirmation-dialog-root {
    .confirmation-dialog-container {
      .confirmation-dialog-paper {
        .confirmation-form {
          .dialog-header {
            .form-heading {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
