@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.item-customization-dialog-root {
  .item-customization-dialog-container {
    .item-customization-dialog-paper {
      width: 100%;
      min-width: 360px;
      max-width: 640px;
      border-radius: 16px;
      box-sizing: border-box;
      .item-customization-form {
        .dialog-header {
          padding: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
          .title-wrapper {
            display: flex;
            align-items: center;
          }
          .close-dialog-icon {
            width: 16px;
            height: 16px;
            cursor: pointer;
          }
        }
        .dialog-content {
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;
          min-height: 300px;
          padding: 0px 16px 16px 16px;
          height: calc(100vh - 320px);
          .dialog-sub-heading {
            padding-top: 16px;
          }
          .add-on-list {
            margin-top: 32px;
            .add-on {
              margin-bottom: 24px;
              .add-on-title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                .group-message {
                  text-transform: capitalize;
                }
                .title-wrapper {
                  display: flex;
                  .options-allowed {
                    color: $santas-gray;
                    margin-inline-start: 5px;
                    font-size: 12px;
                  }
                }
              }
              .add-on-value-list {
                .add-on-value-row {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  .add-on-value {
                    flex-grow: 1;
                    margin-inline-end: 0px;
                    .checkbox-icon,
                    .radio-icon {
                      width: 16px;
                      height: 16px;
                    }
                    .add-on-value-label {
                      width: 100%;
                      .add-on-value-label-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        .add-on-diet-indicator {
                          margin-inline-end: 0px;
                          margin-inline-start: 4px;
                        }
                        .add-on-diet-indicator-disabled {
                          pointer-events: none;
                          opacity: 0.4;
                        }
                        .add-on-text {
                          display: flex;
                          align-items: center;
                          flex-grow: 1;
                        }
                      }
                    }
                  }
                }
                .qr-code-menu-add-on-row {
                  display: flex;
                  .qr-code-menu-add-on-label {
                    width: 100%;
                    display: flex;
                    .add-on-price {
                      flex-grow: 1;
                      display: flex;
                      flex-direction: row-reverse;
                    }
                  }
                }
              }
            }
          }
        }
        .dialog-footer {
          display: flex;
          padding: 24px 16px;
          position: relative;
          box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
          .error-wrapper {
            width: calc(100% - 32px);
            position: absolute;
            top: 1px;
            @include align-center;
            .validation-error-msg {
              color: $cinnabar-color;
            }
          }
          .save-button {
            margin-inline-start: 16px !important;
            height: 40px;
            .save-button-total {
              margin-inline-start: 5px;
            }
          }
          .counter-wrapper {
            width: 100px;
            height: 40px;
            border-color: transparent !important;
            background-color: $alabaster-color;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .item-customization-dialog-root {
    .item-customization-dialog-container {
      .item-customization-dialog-paper {
        .item-customization-form {
          .dialog-header {
            padding: 32px;
          }
          .dialog-content {
            padding: 0px 32px 32px 32px;
            height: calc(100vh - 500px);
          }
          .dialog-footer {
            padding: 32px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .item-customization-dialog-root {
    .item-customization-dialog-container {
      .item-customization-dialog-paper {
        .item-customization-form {
          .dialog-content {
            .form-heading {
              font-size: 24px;
            }
            .add-on-list {
              .add-on {
                .add-on-value-list {
                  .add-on-value-row {
                    .add-on-value {
                      .checkbox-icon,
                      .radio-icon {
                        width: 20px;
                        height: 20px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
