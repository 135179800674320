@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.navigator-wrapper {
  z-index: 998;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white-color;
  position: fixed;
  bottom: 0;
  padding: 13px 0px;
  box-shadow: 0 2px 4px 4px rgba(163, 163, 163, 0.16);
  .navigator-tab {
    width: 100%;
    padding: 5px 15px;
    text-decoration: none;
    cursor: pointer;
    border-right: 1px solid rgba(163, 163, 164, 0.16);
    @include align-center;
    position: relative;
    white-space: nowrap;
    .tab-icon {
      width: 16px;
      height: 16px;
      margin-inline-end: 8px;
    }
    .badge-wrapper {
      top: -15px;
      left: -15px;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .navigator-wrapper {
    display: none;
  }
}
