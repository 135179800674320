@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.explore-wrapper {
  @include wrapper-shimmer;
  height: 100%;
  overflow-y: hidden;
  background-color: $white-color;
  .explore-container {
    @include container-shimmer;
    padding: 0;
  }
}
