@import "../../styles/colors.scss";

.icon-position-ltr {
  position: absolute;
  right: 8px;
  top: 8px;
}
.icon-position-rtl {
  position: absolute;
  left: 5px;
  top: 5px;
}
.favorite-indicator-wrapper {
  padding: 0 2.5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white-color;
  z-index: 100;
  .icon-wrapper {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    .likes-count {
      margin-inline-start: 6px;
    }
    .favorite-icon {
      width: 10px;
      height: auto;
    }
  }
}

@media only screen and (min-width: 768px) {
  .icon-position-ltr {
    top: 8px;
    right: 8px;
  }
  .icon-position-rtl {
    top: 8px;
    left: 8px;
  }
  .favorite-indicator-wrapper {
    .icon-wrapper {
      width: 34px;
      height: 34px;
      .favorite-icon {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }
}
