@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.footer-wrapper {
  margin-bottom: 60px;
  background-color: $alabaster-color;
  .footer-container {
    box-sizing: border-box;
    padding: 32px 24px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    * {
      text-decoration: none;
      list-style-type: none;
      padding: 0px;
    }
    > div {
      margin-bottom: 12px;
    }
    .footer-sub-heading {
      text-transform: uppercase;
      letter-spacing: 2px;
      line-height: 100%;
    }
    .footer-link {
      cursor: pointer;
      margin-bottom: 8px;
      h3 {
        line-height: 140%;
      }
      a {
        all: unset;
      }
    }
    .footer-follow-us {
      .social-media-list {
        display: flex;
        align-items: center;
        .media-link {
          @include align-center;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin: 0px 5px;
          border: 1px solid rgba(100, 110, 131, 0.25);
          .media-img {
            width: 12px;
            height: 12px;
            pointer-events: none; // to make sure children dont inherit background color
          }
          &:hover {
            > .media-img {
              filter: brightness(100); //makes icons white
            }
          }
        }
      }
    }
  }
  .poweredby-text-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    .version-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .footer-wrapper {
    margin-bottom: 0px;
    .footer-container {
      padding: 64px 24px 40px;
      flex-direction: row;
      justify-content: flex-start;
      gap: 10%;
      > div {
        width: 35%;
        margin: 0;
      }
      .footer-link {
        margin-bottom: 8px;
      }
      .footer-follow-us {
        .social-media-list {
          .media-link {
            width: 40px;
            height: 40px;
            .media-img {
              width: 16px;
              height: 18px;
            }
          }
        }
      }
    }
  }
}
