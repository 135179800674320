@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.divider-wrapper {
  @include align-center;
  position: relative;
  background-color: rgba(163, 163, 164, 0.16);
  .divider-caption {
    padding: 0px 10px;
    position: absolute;
    text-transform: capitalize;
    background-color: $white-color;
  }
}

.vertical {
  width: 1px;
  height: 20px;
  margin: auto 25px;
}

.horizontal {
  width: auto;
  height: 2px;
  margin: 26px auto;
}
