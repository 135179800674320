@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.explore-wrapper {
  @include wrapper-shimmer;
  height: 100%;
  overflow-y: hidden;
  background-color: $white-color;
  .explore-container {
    @include container-shimmer;
  }
}

@media only screen and (min-width: 768px) {
  .explore-wrapper {
    margin-top: 0px;
  }
}
