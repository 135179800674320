@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.hero-section-wrapper {
  width: 100%;
  padding-bottom: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 80vh;
  position: relative;
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: -50px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    .brand-logo {
      width: 160px;
      height: auto;
      margin: 0px auto 46px auto;
      @media only screen and (min-width: 1024px) {
        width: 180px;
        margin-bottom: 42px;
      }
    }
    .section-title {
      text-transform: uppercase;
    }
    .section-description {
      max-width: 260px;
      text-align: center;
      margin: 18px auto;
      @media only screen and (min-width: 1024px) {
        max-width: 100%;
        margin-top: 32px;
      }
    }
    .separator {
      margin: 5px 0px 28px 0px;
      @media only screen and (min-width: 1024px) {
        margin: 16px 0px 32px 0px;
      }
    }
    .search-location-widget-wrapper {
      position: relative;
      // text-align: center;
      .fullfilment-list {
        width: 100%;
        display: flex;
        margin-bottom: 16px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .fullfilment-type {
          &:first-child {
            margin-inline-end: 24px;
          }
          .radio-icon {
            width: 18px;
            height: auto;
          }
          .fullfilment-type-label {
            text-transform: capitalize;
          }
        }
      }
      .search-box-wrapper {
        position: relative;

        .location-search-box {
          width: 300px;
          margin: 0 auto;
          box-sizing: border-box;
          background-color: $white-color;
          @media only screen and (min-width: 1024px) {
            width: 460px;
          }
          .search-input-adornment {
            padding-right: 4px;
          }
          .MuiInputBase-root {
            background-color: transparent;
            input {
              padding: 12px;
              font-size: 12px;
              @media only screen and (min-width: 1024px) {
                padding: 16px;
                font-size: 14px;
              }
            }
            .location-icon {
              width: 18px;
              height: auto;
              cursor: pointer;
            }
            .close-search-icon {
              height: auto;
              cursor: pointer;
            }
          }
        }
        .no-border-radius {
          border-radius: 15px 15px 0px 0px;
        }
        .border-radius {
          border-radius: 15px;
        }
        .location-list-wrapper {
          width: 100%;
          height: 300px;
          overflow-y: auto;
          position: absolute;
          background-color: $white-color;
          border-radius: 0px 0px 25px 25px;
          .section-heading {
            padding: 16px 16px 0px;
            text-transform: capitalize;
          }
          .address-item-wrapper {
            cursor: pointer;
            margin-top: 16px;
            display: flex;
            align-items: center;
            padding: 0px 16px 18px 16px;
            border-bottom: 1px solid rgba(163, 163, 164, 0.16);
            &:first-child {
              margin-top: 0px;
            }
            &:hover {
              .address-title {
                font-weight: 700 !important;
              }
            }
            .address-icon {
              width: 40px;
              height: auto;
              display: flex;
            }
            .address-text {
              text-align: left;
              .address-title {
                margin: 5px 0px;
                text-transform: uppercase;
                line-height: 1.45;
                letter-spacing: 1px;
              }
              .address {
                max-width: 280px;
              }
            }
          }
          .empty-search-state-wrapper {
            height: 100%;
            .empty-search-state {
              height: 75%;
              padding: 32px;
              flex-direction: column;
              @include align-center;
              .icon-wrapper {
                width: 64px;
                height: 64px;
                border-radius: 16px;
                background-color: $medium-gray-color;
                @include align-center;
              }
            }
          }
        }
      }
      .search-result-wrapper {
        background-color: $white-color;
        position: absolute;
        width: 100%;
        height: 450px;
        .search-results-wrapper {
          border-radius: 0 0 25px 25px;
          overflow-y: hidden;
        }
      }
    }
  }
}
