@import "../../styles/colors.scss";

.sort-by-filter-wrapper {
  cursor: pointer;
  .filter-button {
    display: flex;
    border-radius: 6px;
    align-items: center;
    position: relative;
    background-color: $alabaster-color;
    .button-icon,
    .chevron-icon {
      width: 16px;
      height: 16px;
      padding: 12px;
    }
    .filter-applied-indicator {
      width: 10px;
      height: 10px;
      top: -4px;
      right: -4px;
      position: absolute;
      border-radius: 50%;
    }
    .filter-name {
      white-space: nowrap;
    }
    .filter-name,
    .chevron-icon {
      display: none;
    }
  }
}

.sort-by-filter-dialog-root {
  width: 100%;
  .sort-by-filter-dialog-container {
    justify-content: flex-start;
    align-items: flex-end !important;
    .sort-by-filter-dialog-paper {
      width: 100%;
      height: 70%;
      margin: 0px;
      border-radius: 8px 8px 0 0;
      .filter-title-wrapper {
        display: flex;
        align-items: center;
        padding: 16px 16px 4px 16px;
        justify-content: space-between;
        .filter-title {
          font-size: 16px;
          line-height: 32px;
        }
        .close-dialog-icon {
          width: 16px;
          height: 16px;
        }
      }
      .filters {
        flex: 1;
        padding: 0px 16px 0px 10px;
        flex-wrap: nowrap !important;
        .filter-container {
          margin: 2px 0px;
          .MuiButtonBase-root {
            width: 16px;
            height: 16px;
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      .sort-by-button-wrapper {
        display: flex;
        padding: 18px 12px;
        justify-content: space-between;
        border-top: 1px solid rgba(163, 163, 164, 0.16);
        .sort-by-action-button {
          height: 32px;
          margin: 0px 5px;
          padding: 0 10px;
          white-space: nowrap;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .sort-by-filter-wrapper {
    .filter-button {
      background-color: transparent;
      .button-icon {
        display: none;
      }
      .chevron-icon {
        padding: 12px;
        padding-right: 0;
      }
      .filter-name,
      .chevron-icon {
        display: block;
      }
      .filter-applied-indicator {
        top: 5px;
        right: 5px;
      }
    }
  }
  .sort-by-filter-dialog-root {
    width: auto !important;
    top: 110px !important;
    .MuiBackdrop-root {
      opacity: 0 !important;
      transform: none !important;
      transition: none !important;
    }
    .sort-by-filter-dialog-container {
      transform: none !important;
      transition: none !important;
      align-items: flex-start !important;
      .sort-by-filter-dialog-paper {
        width: auto;
        height: auto;
        margin: 0px;
        height: 180px;
        // min-width: 200px;
        border-radius: 8px;
        box-shadow: 0 4px 6px 4px rgba(163, 163, 163, 0.16);
        .filter-title-wrapper {
          .filter-title {
            font-size: 14px;
          }
          .close-dialog-icon {
            display: none;
          }
        }
        .sort-by-button-wrapper {
          .sort-by-action-button {
            height: 28px;
          }
        }
      }
    }
  }
}
