@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.delivery-mode-widget-wrapper {
  .section-heading-wrapper {
    margin-bottom: 24px;
    .change-delivery-mode-button {
      cursor: pointer;
      text-transform: uppercase;
    }
  }

  .delivery-mode-list {
    padding: 0px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    list-style-type: none;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .delivery-mode-button {
      min-height: 50px;
      margin: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      min-width: 84%;
      cursor: pointer;
      border-radius: 8px;
      padding: 12px;
      border: 1px solid #9a9a9a;
      border-radius: 8px;
      .radio-title-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
  .deliver-now-widget {
    .order-waiting-text {
      display: inline;
    }
    .order-waiting-time {
      display: inline;
    }
  }
  .deliver-later-widget {
    padding: 8px 0px 0px 0px;
    .available-time-slot {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }
    .time-field-wrapper {
      padding-top: 16px;
    }
  }
  .deliver-timeslot-widget {
    padding: 12px 0px;
  }
  .continue-cta {
    height: 52px;
    width: 94%;
    margin: auto;
    margin-bottom: 30px;
  }
  .continue-cta-landing {
    height: 52px;
    position: absolute;
    bottom: 10px;
    width: 90%;
    margin: auto;
    left: 0;
    right: 0;
  }
}

@media only screen and (min-width: 768px) {
  .delivery-mode-widget-wrapper {
    .deliver-now-widget {
      .order-waiting-text {
        margin-top: 32px;
      }
    }
    .deliver-timeslot-widget {
      padding: 16px 0px;
    }
  }
}
