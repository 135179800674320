@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url(../../assets/fonts/OpenSans-Bold.woff) format("woff");
}

h1 {
  font-family: Open Sans;
  font-size: 1.25rem;
  font-weight: 600;
}

h2 {
  font-family: Open Sans;
  font-size: 1rem;
  font-weight: 400;
  color: rgb(98, 98, 100);
}

a {
  font-family: Open Sans;
  text-decoration: none;
  .goHomeBtn {
    cursor: pointer;
    color: #fff;
    background-color: rgb(80, 62, 157);
    width: 200px;
    padding: 8px 10px;
    margin: 24px auto 48px;
    border: 1px solid;
    border-color: rgb(80, 62, 157);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 64px;
    height: 34px;
    // h3 {
    //     color : #ffffff
    // }
  }
}
