@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.share-widget-wrapper {
  .share-widget-button {
    padding: 9px;
    cursor: pointer;
    margin-inline-start: 16px;
    border-radius: 4px;
    @include align-center;
    .share-widget-icon {
      width: 16px;
      height: auto;
    }
  }
}
.share-widget-dialog-root {
  width: 100%;
  .share-widget-dialog-container {
    align-items: flex-end !important;
    justify-content: flex-start;
    .share-widget-dialog-paper {
      width: 100%;
      height: 70%;
      margin: 0px;
      border-radius: 8px 8px 0 0;
      .share-link-list {
        .share-link {
          height: 56px;
          display: flex;
          cursor: pointer;
          padding: 8px 18px;
          align-items: center;
          box-sizing: border-box;
          justify-content: flex-start;
          border-bottom: 1px solid rgba(163, 163, 164, 0.16);
          .share-link-icon {
            height: auto;
            margin-inline-end: 13px;
          }
          .share-link-text {
            color: $mine-shaft;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .share-widget-wrapper {
    .share-widget-button {
      .share-widget-icon {
        width: 18px;
      }
    }
  }
  .share-widget-dialog-root {
    .MuiBackdrop-root {
      opacity: 0 !important;
      transform: none !important;
      transition: none !important;
    }
    .share-widget-dialog-container {
      transform: none !important;
      transition: none !important;
      align-items: flex-start !important;
      .share-widget-dialog-paper {
        width: auto;
        height: auto;
        margin: 0px;
        min-width: 200px;
        border-radius: 8px;
        box-shadow: 0 4px 6px 4px rgba(163, 163, 163, 0.16);
      }
    }
  }
}
