@import "../../styles/mixins.scss";

.badge-wrapper {
  width: 22px;
  height: 22px;
  top: -10px;
  right: -10px;
  position: absolute;
  border-radius: 50%;
  @include align-center;
}
