@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.nps-feedback-dialog-root {
  .nps-feedback-dialog-container {
    .nps-feedback-dialog-paper {
      box-sizing: border-box;
      padding: 32px 16px;
      width: 100%;
      min-width: 360px;
      max-width: 540px;
      .nps-feedback-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 9px;
        .nps-feedback-close-icon {
          cursor: pointer;
        }
      }
      .nps-feedback-body {
        .nps-feedback-rating-wrapper {
          margin-top: 16px;
          display: flex;
          align-items: center;
          .nps-feedback-rating {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            padding: 8px;
            height: 8px;
            width: 8px;
            margin-inline-end: 8px;
          }
        }
        .nps-feedback-comment {
          box-sizing: border-box;
          margin-top: 16px;
          width: 100%;
          background-color: $alabaster-color;
          padding: 16px 8px;
          border-radius: 5px;
        }
        .nps-feedback-choice-text {
          margin-top: 16px;
          background-color: $alabaster-color;
          padding: 16px 8px;
          border-radius: 5px;
          .nps-feedback-choice-text-placeholder {
            color: $santas-gray;
          }
        }
      }
      .nps-feedback-footer {
        margin-top: 16px;
        .nps-feedback-submit-button {
          padding: 5px 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .nps-feedback-dialog-root {
    .nps-feedback-dialog-container {
      .nps-feedback-dialog-paper {
        padding: 32px;
        position: absolute;
        bottom: 10%;
        right: 0;
        .nps-feedback-body {
          .nps-feedback-rating-wrapper {
            .nps-feedback-rating {
              padding: 16px;
            }
          }
        }
      }
    }
  }
}
